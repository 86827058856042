<template>
  <div>
    <h1>DASHBOARD</h1>
  </div>
</template>

<script>
export default {
  name: "Dashboard"
};
</script>

<style lang="scss" scoped></style>
