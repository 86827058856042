import firebase from "firebase/app"
import "firebase/auth";
import "firebase/firestore";

// firebase init - add your own config here
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyB06Y2floZs67OmUx3vbZ5ncn9AvNwnbjg",
  authDomain: "growfolio-67061.firebaseapp.com",
  projectId: "growfolio-67061",
  storageBucket: "growfolio-67061.appspot.com",
  messagingSenderId: "63380153851",
  appId: "1:63380153851:web:919cdc4dafe429ba28d75e",
  measurementId: "G-YSTJ6CT87W"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

// utils
const db = firebase.firestore();
const auth = firebase.auth();

// collection references
const usersCollection = db.collection("users");
// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  db,
  auth,
  usersCollection
  // postsCollection,
  // commentsCollection,
  // likesCollection
};
